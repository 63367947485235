html, body {
  min-height:100%;
  height:100%;
  overflow-x:hidden;
  display:block;
  background: #f3f3f3 !important;
  background: #f3f3f3 !important;
}

.border-left-range {
  border-left: 0.25rem solid orange !important;
}

.text-range {
  color: orange !important;
}

.border-left-info {
  border-left: 0.25rem solid #36b9cc !important;
}

.text-info {
  color: #36b9cc !important;
}

.border-left-warning {
  border-left: 0.25rem solid #f6c23e !important;
}

.text-warning {
  color: #f6c23e !important;
}

.border-left-secondary {
  border-left: 0.25rem solid #6c757d !important;
}

.text-secondary {
  color: #6c757d !important;
}

.border-left-primary {
  border-left: 0.25rem solid #4e73df !important;
}

.text-primary {
  color: #4e73df !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.text-xs {
  font-size: .7rem;
}

.pb-2, .py-2 {
  padding-bottom: 0.5rem !important;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

span.date-custom-show {
  color: #000;
  float: right;
  font-size: 10px;
  font-weight: bold;
}

.shadow { 
  cursor: pointer;
}

.card {
  text-align: center;
}